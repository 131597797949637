<template>
  <div class="columns" >
      <div class="column is-one-third">
        <card-component
          title="1. Run PCA"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="dataset"
            >
              <dataset-select
                label="Select a dataset"
                :dataset.sync="datasetName"
                v-model="datasetName"
                :withVarname="false"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                icon="table"
                pack="fa"
                :minNumericVariable="minNumVal"
                :minVariable="3"
                :minSample="5"
                :minCatVariable="0"
                expanded
              />
            </validation-provider>
            <b-field style="margin-top:15px"
              label=""
            >
              <b-switch
                v-model="pca"
                >
                Force PCA (i.e., discard categorical variables)
              </b-switch>
            </b-field>
            <hr />
            <div class="has-text-centered">
              <b-button rounded
                type="is-primary"
                class="button-shadow"
                @click.prevent="handleSubmit(run)"
              >
                <span class="icon is-small"><i class="fa fa-search"></i></span>
                <span> Run </span>
              </b-button>
            </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component
            title="2. PCA results"
          >
        </card-component>
        <error :type='"notifications"' />
        <card-no-result v-if="!resultsLoaded">
          <strong>How to set options?<br></strong>
          The analysis performed in this workflow is “<strong>Factorial Analysis
          of Mixed Data (FAMD)</strong>”, which is a generalization of PCA to
          the cases where categorical variables are present in the dataset.
          <ul>
            <li>when <strong>your dataset contains only numeric
            variables</strong>, this analysis is identical to PCA;</li>
            <li>when <strong>your dataset contains numeric and categorical
            variables</strong>, you can use the option “Force PCA” to discard
            categorical variables and perform a PCA.</li>
          </ul>
          <br>
          <strong>Important note</strong>: PCA is performed with raw values
          (data are not scaled to unit variance before they are processed).
          <strong>If you want to perform scaled PCA</strong>, go to the menu
          “Edit / Dataset edition” and scale your dataset before PCA.
        </card-no-result>
        <div v-else>
          <card-component>
            <b-tabs @input="displayResult">
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item label="Inertia table" icon="table" value="summary" />
            </b-tabs>
          </card-component>
          <card-component v-show="currentResult==='plots'">
            <plotly
                v-if="graphicals.length===1"
                :data="graphicals[0]"
              ></plotly>
              <plotly-list
                v-else-if="graphicals.length>1"
                :data="graphicals"
              ></plotly-list>
          </card-component>
          <card-component
              v-show="currentResult==='summary'"
              :title="basicTable.title"
            >
              <basic-table :data="basicTable"/>
          </card-component>
        </div>
      </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import Plotly from '@/components/rresults/Plotly.vue'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useUtilsStore } from '@/stores/utils'

export default {
  name: 'RPCAStep',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': Plotly,
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      PCAobjectName: null,
      currentResult: 'plots',
      resultsLoaded: false,
      pca: true
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, utilsStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    minNumVal: function () {
      if (this.pca) {
        return 3
      } else {
        return 0
      }
    },
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    params: function () {
      return {
        'func_name': 'r_famd',
        'datasetName': this.datasetName,
        'pca': this.pca,
        'out_graph': true
      }
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    afterRun: function (data) {
      this.PCAobjectName = data.ObjectName
      this.displayResult('plots')
      this.$emit('is-computed',
        { 'ObjectName': this.PCAobjectName,
          'datasetName': this.datasetName
        })
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
